import jwt_decode from "jwt-decode"
import currency from "currency.js"
import { DateTime } from "luxon"
import { Preferences } from "@capacitor/preferences"
import { useCookies } from "vue3-cookies"
import { useAuth } from "@/services/useAuth"
import { useTracking } from "@/services/useTracking"
import store from "../store/index"
const { cookies } = useCookies()
import { useAlert } from "@/services/useAlert"
import { ROLES } from "@/constants/roles"
import { ShippingAddress } from "@/types"

/**
 * generatePasswordRand generate an password numeric, alphabetic o alphanumeric
 * @param length length of password
 * @param type type of password (numeric, alphabetic, rand)
 * @returns password string
 */
export const generatePasswordRand = (length: number, type = "alphanumeric") => {
	let characters = ""
	if (type == "numeric") {
		characters = "0123456789"
	}
	if (type == "alphabetic") {
		characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
	}
	if (type == "alphanumeric") {
		characters =
			"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	}

	let pass = "0123456789".charAt(Math.floor(Math.random() * 10))
	const caractersInsertInStart = 1

	for (let i = 0; i < length - caractersInsertInStart; i++) {
		pass += characters.charAt(Math.floor(Math.random() * characters.length))
	}
	return pass
}

/**
 * Verify if jwt token is alive or not, return `true` or `false`
 * @param token token jwt
 * @returns boolean
 */
export const tokenAlive = (token: any): boolean => {
	const t: any = jwt_decode(token)
	const copy = new Date()
	copy.setTime(t.exp * 1000)

	if (Date.now() > t.exp * 1000) return false
	return true
}

/**
 * formatPrice format a number in currency format
 * @param price number to format
 * @returns numeric chain. Example, $1.000
 */
export const formatPrice = (price: number): string => {
	return currency(price, { precision: 0, separator: "." }).format()
}

/**
 * getTimeElapsed calculate time elapsed between two dates ( current date and previous date)
 * @param date previous date string
 * @returns object
 */
export const getTimeElapsed = (date: string) => {
	const nacimiento: any = new Date(date)
	const hoy: any = new Date()

	let timeElapsed = hoy - nacimiento
	const segs = 1000
	const mins = segs * 60
	const hours = mins * 60
	const days = hours * 24
	const months = days * 30.416666666666668
	const years = months * 12

	const yearsElapsed = Math.floor(timeElapsed / years)

	timeElapsed = timeElapsed - yearsElapsed * years
	const monthsElapsed = Math.floor(timeElapsed / months)

	timeElapsed = timeElapsed - monthsElapsed * months
	const daysElapsed = Math.floor(timeElapsed / days)

	timeElapsed = timeElapsed - daysElapsed * days
	const hoursElapsed = Math.floor(timeElapsed / hours)

	timeElapsed = timeElapsed - hoursElapsed * hours
	const minutesElapsed = Math.floor(timeElapsed / mins)

	timeElapsed = timeElapsed - minutesElapsed * mins
	const secondsElapsed = Math.floor(timeElapsed / segs)

	return {
		yearsElapsed,
		monthsElapsed,
		daysElapsed,
		hoursElapsed,
		minutesElapsed,
		secondsElapsed,
	}
}

/**
 * Formatea una fecha en un formato específico.
 * @param {string} date - La fecha a formatear en formato ISO.
 * @param {object} [config] - Opciones de configuración (opcional).
 * @param {boolean} [config.year=false] - Indica si se incluye el año en el formato.
 * @param {string} [config.format="cccc ', ' dd 'de' LLLL"] - Formato de fecha personalizado.
 * @returns {string} La fecha formateada en el formato especificado.
 */
export const formatDate = (date: string, config?: any) => {
	const _config = { year: false, format: "cccc ', ' dd 'de' LLLL", ...config }
	// Si se proporciona la opción 'year' y es verdadera, se añade ' y' al formato.
	if (_config.year) {
		_config.format = `${_config.format} y`
	}

	// Utiliza la biblioteca DateTime para formatear la fecha.
	const result = DateTime.fromISO(date, {
		zone: "UTC",
		locale: "es",
	}).toFormat(_config.format)

	return result.charAt(0).toUpperCase() + result.slice(1) // First letter to upper case
}

export const isDatePreviousToTheCurrentDate = (date: string) => {
	if (date) {
		const currentDate: any = DateTime.now()
		const dateToEvaluate: any = DateTime.fromISO(date, {
			zone: "UTC",
			locale: "es",
		})

		const dateToEvaluateDay = dateToEvaluate["c"].day
		const dateToEvaluateMonth = dateToEvaluate["c"].month
		const dateToEvaluateYear = dateToEvaluate["c"].year
		const currentDay = currentDate["c"].day
		const currentMonth = currentDate["c"].month
		const currentYear = currentDate["c"].year

		if (dateToEvaluateYear > currentYear) {
			return false
		}

		if (dateToEvaluateMonth > currentMonth) {
			return false
		}

		if (dateToEvaluateMonth == currentMonth) {
			if (dateToEvaluateDay >= currentDay) {
				return false
			}

			return true
		}

		return true
	}
}

/**
 * If customer exist in store return true, otherwase return false
 * @returns {boolean}
 */
export const isThereACustomer = async (): Promise<boolean> => {
	if (store.getters["auth/getCustomerConfiguredBySeller"]) {
		return true
	}
	// If no exist in vuex store, then find it in local store
	const customer: any = await Preferences.get({ key: "customer" })
	if (customer.value) {
		store.commit(
			"auth/setCustomerConfiguredBySeller",
			JSON.parse(customer.value)
		)
		return true
	}

	return false
}

export const isUserInStore = () => {
	return store.getters["auth/getUser"] ? true : false
}

export const isShippingAddressFixedInStore = () => {
	return store.getters["auth/getShippingAddressFixed"] ? true : false
}

export const setUserInStore = async () => {
	await store.dispatch("auth/getDataUserLoggedIn")
}

export const isUserLogin = async () => {
	const { getRefreshToken } = useAuth()

	if (!store.getters["auth/getToken"]) {
		const token: any = await Preferences.get({ key: "token" })
		const refresh_token: any = cookies.get("refreshToken")

		if (token.value) {
			if (tokenAlive(token.value)) {
				// Token is alive in localStorage
				// Set Token in Store
				store.commit("auth/setToken", token.value)
				if (refresh_token && refresh_token !== "undefined") {
					store.commit("auth/setRefreshToken", refresh_token)
				}

				if (!isUserInStore()) {
					await setUserInStore()
				}

				const thereACustomer = await isThereACustomer()

				if (thereACustomer) {
					let customer: any = await Preferences.get({
						key: 'customer',
					})

					customer = JSON.parse(customer.value)
					store.commit(
						'auth/setShippingAddresses',
						customer.shipping_addresses
					)
				}

				// Store shipping address fixed by the user from header pin location
				if (!isShippingAddressFixedInStore()) {
					const shippingAddressFixed = await Preferences.get({
						key: 'shippingAddressFixed',
					})

					if (shippingAddressFixed.value) {
						store.commit(
							'auth/setShippingAddressFixed',
							JSON.parse(shippingAddressFixed.value)
						)
					}
				}

				return true
			} else {
				if (refresh_token) {
					try {
						const response = await getRefreshToken({
							token: token.value,
							refreshToken: refresh_token,
						})

						if (response.status === 200) {
							await store.dispatch('auth/saveDataLogin', {
								tokenReturn: response.data.tokenReturn,
								refreshToken: response.data.refreshToken,
							})

							if (!isUserInStore()) {
								setUserInStore()
							}

							return true
						} else {
							// Loguot
							store.dispatch('auth/userLogout')
							return false
						}
					} catch (error: any) {
						// Loguot
						store.dispatch('auth/userLogout')
						return false
					}
				} else {
					// Refresh token is dead
					// Loguot
					store.dispatch('auth/userLogout')
					return false
				}
			}
		} else {
			return false
		}
	} else {
		if (store.getters['auth/getAliveToken']) {
			// Token is alive in store
			return true
		} else {
			try {
				const response = await getRefreshToken({
					token: store.getters['auth/getToken'],
					refreshToken: store.getters['auth/getRefreshToken'],
				})

				if (response.status === 200) {
					await store.dispatch('auth/saveDataLogin', {
						tokenReturn: response.data.tokenReturn,
						refreshToken: response.data.refreshToken,
					})
					return true
				}
			} catch (error: any) {
				// Loguot
				store.dispatch("auth/userLogout")
				return false
			}
		}
	}
}

export const encodeURIParamsToRequest = (
	argumentsObject: any,
	othersParamsString = ""
) => {
	let params = "?"
	const argumentsKeys = Object.keys(argumentsObject)

	argumentsKeys.forEach(argumentKey => {
		if (argumentsObject[argumentKey]) {
			params += `${argumentKey}=${argumentsObject[argumentKey]}&`
		}
	})

	// Configuration of modal filter params in case exists
	return `${params}${othersParamsString}`
}

export const userHasCoverage = async (cityId: string, stateId: string) => {
	if (store.state.auth.isThereCoverage != null) {
		return store.state.auth.isThereCoverage // true or false
	} else {
		const { verifyCoverage } = useTracking()

		try {
			const response = await verifyCoverage(cityId, stateId)
			store.commit("auth/setCoverage", response.data.data.coverage)
			return store.state.auth.isThereCoverage
		} catch (error) {
			store.commit("auth/setCoverage", false)
			return false
		}
	}
}

export const showAlertIfUserHasNoCoverage = async (): Promise<boolean> => {
	const shippingAddressFixed: ShippingAddress = await store.getters[
		'auth/getShippingAddressFixed'
	]
	const customerConfiguredBySeller = await store.getters[
		'auth/getCustomerConfiguredBySeller'
	]
	const user = store.getters['auth/getUser']
	const currentUserRole = await store.getters['auth/getRole']

	const { presentAlert } = useAlert()

	if (currentUserRole !== ROLES.GUEST) {
		let userCityId = user.customer.cityId
		let userStateId = user.customer.stateId
		let userCityName = user.customer.city
		let userStateName = user.customer.state

		// Address fixed by user from header location pin
		if (shippingAddressFixed) {
			userCityId = shippingAddressFixed.cityid
			userStateId = shippingAddressFixed.stateid
			userCityName = shippingAddressFixed.city
			userStateName = shippingAddressFixed.state
		}

		// Address fixed by seller from customer configured
		if (customerConfiguredBySeller) {
			userCityId = customerConfiguredBySeller.cityId
			userStateId = customerConfiguredBySeller.stateId
			userCityName = customerConfiguredBySeller.city
			userStateName = customerConfiguredBySeller.state
		}

		const hasCoverage = await userHasCoverage(userCityId, userStateId)

		if (!hasCoverage) {
			await presentAlert({
				header: 'Sin cobertura',
				message: `Actualmente no tenemos cobertura para ${userCityName}, ${userStateName}.`,
				handleDismiss: () => true,
			})

			return false
		}

		return true
	}

	return true
}

export const isValidEmail = (email: string): boolean => {
	const regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return regex.test(email)
}

export function isValidPhone(phone: string) {
	// Elimina espacios, guiones o paréntesis
	const cleanedPhone = phone.replace(/[\s()-]/g, '');

	// Expresión regular para validar números de teléfono en Colombia
	const regexPhone = /^(\d{7}|\d{10}|0\d{8})$/;
	const regexCelular = /^3\d{9}$/; // Números celulares
	const regexFijo = /^0?\d{7}$/; // Números fijos (ej. 7 dígitos)

	if (regexPhone.test(cleanedPhone)) {
		if (regexCelular.test(cleanedPhone)) {
			// Número de celular válido
			return true;
		} else if (regexFijo.test(cleanedPhone)) {
			// Número de teléfono fijo válido
			return true;
		} else {
			// Número de teléfono fijo con código de área válido
			return true;
		}
	} else {
		// Número de teléfono no válido
		return false;
	}
}

export function firstLetterToUpperCase(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isVisibleInViewport(element?: HTMLElement) {
	if (element) {
		const distance = element?.getBoundingClientRect()
		return (
			distance?.top <
			(window.innerHeight || document.documentElement.clientHeight)
		)
	}

	return false
}

export interface VerifyQuantityOption {
	productIsPresale?: boolean
	productVersionIsExcludedFromPresale?: boolean
	productMinQuantity?: number
	productStock?: number
	quantityToAdd: number | string
}
export const verifyIfQuantityIsValidToAdd = ({
	quantityToAdd,
}: VerifyQuantityOption) => {
	// debugger
	const { presentAlert } = useAlert()

	// STEP 1: verify valid number
	if (typeof quantityToAdd === "string") {
		quantityToAdd = Number(quantityToAdd)

		if (isNaN(quantityToAdd)) {
			presentAlert({
				header: "No es un número válido.",
				message: "Debes ingresar un número valido.",
				buttons: [{ text: "CORREGIR", role: "cancel" }],
			})

			return false
		}
	}

	// STEP 2: verify number different of ziro
	// if ( quantityToAdd === 0 ) {
	//     presentAlert({
	//         header: 'Cantidad mínima.',
	//         message: 'Debes ingresar una cantidad mínima.',
	//         buttons: [{ text: 'CORREGIR', role: 'cancel' }]
	//     })

	//     return false;
	// }

	// STEP 3: Verify that it is not a decimal or a float
	if (!Number.isInteger(quantityToAdd)) {
		presentAlert({
			header: "No uses puntos ni comas.",
			message: "Debes ingresar un número sin puntos ni comas.",
			buttons: [{ text: "CORREGIR", role: "cancel" }],
		})

		return false
	}

	// STEP 4: Verify that it is greater that or equal to minimum quantity
	// if ( quantityToAdd < (productMinQuantity || 1) ) {
	//     presentAlert({
	//         header: 'Cantidad mínima no permitida.',
	//         message: `Debes ingresar mínimo ${productMinQuantity} unidades.`,
	//         buttons: [{ text: 'CORREGIR', role: 'cancel' }]
	//     })

	//     return false;
	// }

	// STEP 5: Verify that is a multiple of productMinQuantity
	// if ( (quantityToAdd % productMinQuantity) !== 0 ) {
	//     presentAlert({
	//         header: 'Cantidad no permitida.',
	//         message: `Debes ingresar unidades de ${productMinQuantity} en ${productMinQuantity}.`
	//     })

	//     return false;
	// }

	// STEP 6: Verify if its a presale product
	// if ( productIsPresale ) {
	//     // If product isn´t excluded from presale, then user can add any quantity
	//     if ( !productVersionIsExcludedFromPresale ) {
	//         return true
	//     }

	//     // Is excluded from presale, then verify stock
	//     if ( quantityToAdd > productStock ) {
	//         presentAlert({
	//             header: ' ¡Atención!',
	//             subHeader: 'Stock máximo sobrepasado.',
	//             message: `Lo sentimos, solo quedan ${productStock} unidades disponibles.`,
	//             buttons: [{ text: 'CORREGIR', role: 'cancel' }]
	//         })

	//         return false;
	//     }
	// }

	// STEP 7: If isn´t presale, then verify stock
	// if ( Number(quantityToAdd) > productStock ) {
	//     presentAlert({
	//         header: ' ¡Atención!',
	//         subHeader: 'Stock máximo sobrepasado.',
	//         message: `Lo sentimos, solo quedan ${productStock} unidades disponibles.`,
	//         buttons: [{ text: 'CORREGIR', role: 'cancel' }]
	//     })

	//     return false;
	// }

	return true
}

export const showAlertIfNoCustomerSelected = async (
	callbackConfirm: () => void,
	callbackCancel: () => void
) => {
	const { presentAlertConfirm } = useAlert()
	const currentUserRole = await store.getters["auth/getRole"]

	if (currentUserRole !== "Seller") {
		return true
	}

	const customer = await isThereACustomer()
	if (customer) {
		return true
	}

	presentAlertConfirm({
		header: "Selecciona un cliente",
		message:
			"Debes tener seleccionado un cliente para agregar productos. <br> ¿Deseas seleccionar un cliente?",
		textButtonConfirm: "Seleccionar cliente",
		callbackConfirm,
		callbackCancel,
	})

	return false
}

function setQueryString(
	currentPath: string,
	newQueryString: { key: string; value: string }
) {
	if (newQueryString.value) {
		if (!currentPath.includes(newQueryString.key)) {
			currentPath = `${currentPath}${
				currentPath.includes("?") ? "&" : "?"
			}${newQueryString.key}=${newQueryString.value}`
		}
	}

	return currentPath
}

const getMonths = () => {
	const monthsArr = []
	let monthObj = DateTime.now().startOf("year")
	for (let i = 0; i < 12; i += 1) {
		monthsArr.push(monthObj.toFormat("MM"))
		monthObj = monthObj.plus({ month: 1 })
	}

	return monthsArr
}

const getYears = () => {
	const yearsArr = []
	let yearObj = DateTime.now()
	for (let i = 0; i < 10; i += 1) {
		yearsArr.push(yearObj.toFormat("yyyy"))
		yearObj = yearObj.plus({ year: 1 })
	}

	return yearsArr
}

const calculatePaymentMethodDiscount = (discount_type: string, discount_amount: string, total_order: number): number => {
	if (discount_type) {
		const amount = Number(discount_amount)
		const total = total_order || 0

		if (discount_type == 'percentage') {
			return ((total * amount) / 100)
		} else {
			return amount
		}
	}

	return 0
}

export function formatDateWithDash(date: Date) {
	// Creamos un objeto Date a partir del argumento si es necesario
	if (typeof date === "string") {
		date = new Date(date)
	}

	const año = date.getFullYear()
	const mes = (date.getMonth() + 1).toString().padStart(2, "0") // Agregamos un 0 al principio si el mes tiene un solo dígito
	const dia = date.getDate().toString().padStart(2, "0") // Agregamos un 0 al principio si el día tiene un solo dígito

	return `${año}-${mes}-${dia}`
}

export default {
	generatePasswordRand,
	tokenAlive,
	formatPrice,
	getTimeElapsed,
	formatDate,
	isDatePreviousToTheCurrentDate,
	isThereACustomer,
	isUserLogin,
	encodeURIParamsToRequest,
	userHasCoverage,
	showAlertIfUserHasNoCoverage,
	isValidEmail,
	isValidPhone,
	firstLetterToUpperCase,
	isVisibleInViewport,
	verifyIfQuantityIsValidToAdd,
	showAlertIfNoCustomerSelected,
	setQueryString,
	getMonths,
	getYears,
	calculatePaymentMethodDiscount,
	formatDateWithDash,
}
